import { useParams } from 'react-router-dom'
import AccountAddressForm from '~/src/components/profile/account/address/AccountAddressForm'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import ContentContainer from '~/src/components/utils/layout/ContentContainer'
import { useEoValue } from '~/src/hooks/useEoState'
import { CUSTOMER_ADDRESS_BY_ID } from '~/src/stores/customer'

const AddressDetail = () => {
  const params = useParams()
  const address = useEoValue(CUSTOMER_ADDRESS_BY_ID(Number(params.addressId)))

  return (
    <ContentContainer>
      <DocumentTitleInjector pageName={'ADDRESS'} />
      <AccountAddressForm address={address} />
    </ContentContainer>
  )
}

export default AddressDetail
